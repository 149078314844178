.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-bottom: 2px solid #e6e6e6;
  margin-bottom: 20px;
}

.logo img {
  height: 75px;
  content: url("../../public/images/ajcslogo2.png");
}

.dnavigation {
  width: 100%;
}

.dnavigation ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.dnavigation ul li {
  margin: 0 auto;
}

.dnavigation ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  transition: color 0.3s ease;
}

.dnavigation ul li a:hover {
  color: #ff7a00;
}

.back-link {
  display: block;
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
  text-align: center;
}

.dienstleistungen-content h1 {
  text-align: center;
  margin-bottom: 20px;
}

.dienstleistungen-content {
  padding: 20px;
  background-color: #f9f9f9;
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.dienstleistung {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column; /* Bilder und Text übereinander */
  align-items: center;
  text-align: left;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
}

.dienstleistung h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 10px;
}

.dienstleistung img {
  max-width: 300px; /* Verdoppelte Größe der Bilder */
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px; /* Fügt einen Abstand zwischen Bild und Text hinzu */
}

.dienstleistung p {
  margin-bottom: 20px;
}

.dienstleistung ul {
  margin-top: 20px;
  padding-left: 20px;
}

.dienstleistung ul li {
  margin-bottom: 10px;
}

/* Mobile Anpassungen */
@media (max-width: 768px) {
  .header {
    justify-content: center;
  }

  .dnavigation ul {
    width: 100%;
    text-align: center;
  }

  .dnavigation ul li {
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }

  .dnavigation ul li a {
    font-size: 18px;
  }

  .dienstleistung {
    text-align: center;
  }

  .dienstleistung img {
    max-width: 80%;
  }

  .dienstleistung p {
    max-width: 100%;
  }
}
