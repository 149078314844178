.partner-section {
  display: flex;
  align-items: center;
  padding: 50px;
  background-color: #fff;
  justify-content: space-between;
  flex-wrap: wrap; /* Erlaubt das Umschlagen der Inhalte bei kleineren Bildschirmen */
}

.partner-content {
  flex: 1;
  margin-right: 20px;
  text-align: left;
  padding-left: 20px; /* Abstand zwischen Text und Linie */
}

.partner-content h2 {
  font-size: 36px;
  color: #333;
  margin-bottom: 10px; /* Platz unter der Überschrift */
  position: relative;
}

.partner-content h2::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px; /* Höhe der Linie */
  background-color: #ff7a00; /* Farbe der Linie */
  margin-top: 10px; /* Abstand nach oben */
}

.partner-content p {
  font-size: 18px;
  color: #555; /* Graue Schriftfarbe */
  line-height: 1.6;
  margin-top: 30px; /* Abstand vom oberen Textblock */
}

.partner-image {
  flex: 1;
  text-align: center; /* Zentriert das Bild in der mobilen Ansicht */
}

.partner-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Mobile Anpassungen */
@media (max-width: 768px) {
  .partner-section {
    flex-direction: column; /* Inhalte untereinander anordnen */
    padding: 20px; /* Weniger Padding auf kleinen Bildschirmen */
  }

  .partner-content {
    margin-right: 0;
    padding-left: 0; /* Kein Padding links auf mobilen Geräten */
    text-align: center; /* Text zentrieren für die mobile Ansicht */
  }

  .partner-content h2 {
    font-size: 28px; /* Kleinere Überschrift auf mobilen Geräten */
  }

  .partner-content p {
    font-size: 16px; /* Kleinere Schriftgröße für Text */
    margin-top: 20px; /* Weniger Abstand zwischen den Textabsätzen */
  }

  .partner-image img {
    max-width: 80%; /* Bildgröße etwas reduzieren auf mobilen Geräten */
    margin-top: 20px; /* Abstand zum Textblock */
  }
}
