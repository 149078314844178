.hero-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  height: 500px;
  overflow: hidden;
}

.hero-section::before {
  content: "";
  position: absolute;
  bottom: 8px; /* Etwas nach oben versetzt für Parallelität */
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ff7a00;
  transform: skewY(-2deg); /* Genauere Berechnung */
  transform-origin: bottom left;
  z-index: 2;
}

.hero-background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center right;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 40px), 0 100%);
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 3;
  max-width: 50%;
  color: #333;
}

.hero-content h1 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ff7a00;
}

/* Mobile Anpassungen */
@media (max-width: 768px) {
  .hero-section {
    padding: 20px;
    height: 400px;
    justify-content: center;
  }

  .hero-background {
    background-position: center;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 20px),
      /* Weniger starkes Abschneiden für kleinere Bildschirme */ 0 100%
    );
  }

  .hero-content {
    max-width: 100%;
    text-align: center;
  }

  .hero-content h1 {
    font-size: 36px;
  }

  .hero-section::before {
    height: 5px; /* Die Linie etwas dünner für kleinere Bildschirme */
  }
}
