.service-portfolio {
  padding: 50px;
  background-color: #f5f5f5;
  text-align: center;
}

.service-portfolio h2 {
  font-size: 30px;
  color: #333;
  margin-bottom: 40px;
}

.services {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.service {
  flex: 1;
  max-width: 200px;
  text-align: center;
  text-decoration: none; /* Entfernt die Unterstreichung */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service:hover {
  transform: translateY(-5px); /* Leichter Effekt beim Hover */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Schatten bei Hover */
}

.service img {
  max-width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.service p {
  color: #333;
  font-weight: bold;
  margin-top: 10px;
}

/* Hover-Effekt für den Text */
.service p:hover {
  color: #ff7a00; /* Farbe beim Hover */
}

/* Mobile Anpassungen */
@media (max-width: 768px) {
  .services {
    flex-direction: column; /* Stapelt die Services vertikal */
    align-items: center; /* Zentriert die Services */
    gap: 30px; /* Größerer Abstand zwischen den Services */
  }

  .service {
    max-width: 300px; /* Etwas breiter für Mobilgeräte */
  }

  .service img {
    max-width: 150px; /* Größere Bilder für Mobilgeräte */
  }

  .service-portfolio h2 {
    font-size: 24px; /* Kleinere Schriftgröße für den Titel */
  }
}
