.impressum-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
}

.inavigation {
  text-align: center;
  margin-bottom: 20px;
}

.back-link {
  text-decoration: none;
  color: #ff7a00;
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.back-link:hover {
  color: #cc6200;
}

.impressum-content h1 {
  font-size: 32px;
  color: #ff7a00;
  text-align: center;
  margin-bottom: 30px;
}

.impressum-content p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}
