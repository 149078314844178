.header {
  display: flex;
  justify-content: space-between; /* Logo links, Navigation rechts */
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-bottom: 2px solid #e6e6e6;
}

.logo {
  flex: 1; /* Das Logo nimmt den verfügbaren Platz links ein */
}

.logo img {
  height: 75px;
  content: url("../../public/images/ajcslogo2.png");
}

.navigation {
  display: none; /* Navigation standardmäßig ausblenden */
}

.hamburger {
  display: none; /* Standardmäßig ausgeblendet für Desktop */
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  cursor: pointer;
  z-index: 10;
}

.hamburger div {
  width: 100%;
  height: 3px;
  background-color: #333;
}

/* Für Desktop-Ansicht */
@media (min-width: 769px) {
  .hamburger {
    display: none;
  }

  .navigation {
    display: flex; /* Sichtbar auf größeren Bildschirmen */
    margin-left: auto; /* sorgt dafür, dass die Navigation rechts ausgerichtet wird */
  }

  .navigation ul {
    list-style: none;
    display: flex;
    gap: 20px;
  }

  .navigation ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    transition: color 0.3s ease;
  }

  .navigation ul li a:hover {
    color: #ff7a00;
  }
}

/* Für mobile Ansicht */
/* Für mobile Ansicht */
@media (max-width: 768px) {
  .logo {
    flex: 1; /* Das Logo bleibt auf der linken Seite */
  }

  .hamburger {
    display: flex; /* sichtbar für mobile Ansicht */
  }

  .navigation.active {
    display: flex; /* Navigation wird sichtbar, wenn Menü aktiv ist */
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 9;
  }

  .navigation ul {
    list-style: none; /* Entfernt die Punkte vor den Links */
    padding: 0;
    margin: 0;
    flex-direction: column;
    align-items: center; /* Zentriert die Links */
    width: 100%;
  }

  .navigation ul li {
    margin: 10px 0; /* Fügt Abstand zwischen den Links hinzu */
    width: 100%;
    text-align: center;
  }

  .navigation ul li a {
    color: #333;
    text-decoration: none;
    display: block;
    padding: 10px 0;
    width: 100%;
    background-color: #f5f5f5;
    font-weight: bold;
  }

  .navigation ul li a:hover {
    color: #ff7a00;
  }
}
