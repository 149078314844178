.footer {
  padding: 20px;
  background-color: #333;
  color: #fff;
  text-align: center;
  width: 100%; /* Stellt sicher, dass der Footer die gesamte Breite des Bildschirms einnimmt */
  box-sizing: border-box; /* Verhindert, dass Padding die Breite des Footers überschreitet */
}

.footer p {
  margin: 0;
  font-size: 14px;
  word-wrap: break-word; /* Text wird bei Bedarf umgebrochen, um Überlauf zu verhindern */
}

/* Mobile Anpassungen */
@media (max-width: 768px) {
  .footer {
    padding: 15px; /* Reduziertes Padding für kleinere Bildschirme */
  }

  .footer p {
    font-size: 12px; /* Kleinere Schriftgröße für Mobilgeräte */
  }
}
